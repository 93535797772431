import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    DotChartOutlined,
    LoadingOutlined,
    HomeOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    AliwangwangOutlined,
    AppstoreAddOutlined,
    SketchOutlined,
    MenuUnfoldOutlined,
    SearchOutlined,
    CloudServerOutlined,
    QuestionCircleOutlined,
    SyncOutlined,
    ShopOutlined,
    SettingOutlined,
    BugOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    Button,
    Input,
    Layout,
    Menu,
    Spin,
    Table,
    Tag,
    FloatButton,
    theme,
    Typography,
    Avatar,
    List,
    Skeleton,
    Switch, Row, Col, Result
} from 'antd';
import {AuthContext} from '../../contexts/AuthContext';
import axios from "axios";
import Highlighter from 'react-highlight-words';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import UtmStatistic from "../UtmStatistic";
import CoreModules from "../CoreModules";

const {Title} = Typography;
const {Header, Sider, Content} = Layout;
const AdminPanel = () => {
    const [collapsed, setCollapsed] = useState(true);
    const authContext = useContext(AuthContext)
    const navigate = useNavigate(); // Отримання об'єкту history з react-router-dom

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return <Layout className={'app_layout'}>
        <FloatButton.BackTop visibilityHeight={100}/>
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="demo-logo-vertical"/>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['1']}
                items={[
                    {
                        key: '0',
                        icon: <HomeOutlined/>,
                        label: 'Dashboard',
                        onClick: () => {
                            navigate('/dashboard/home')
                        },
                    }, {
                        key: '1',
                        icon: <DotChartOutlined/>,
                        label: 'UTM',
                        onClick: () => {
                            navigate('/dashboard/utm')
                        },
                    },
                    {
                        key: '2',
                        icon: <ShopOutlined/>,
                        label: 'Сайти',
                        onClick: () => {
                            navigate('/dashboard/sites')
                        },
                    },
                    {
                        key: '3',
                        icon: <SketchOutlined/>,
                        label: 'Товари',
                        onClick: () => {
                            navigate('/dashboard/products')
                        },
                    },
                    {
                        key: '4',
                        icon: <BugOutlined/>,
                        label: 'Системне',
                        onClick: () => {
                            navigate('/dashboard/system')
                        },
                    },
                    {
                        key: '6',
                        icon: <AppstoreAddOutlined/>,
                        label: 'Модулі',
                        onClick: () => {
                            navigate('/dashboard/modules')
                        },
                    },
                    {
                        key: '7',
                        icon: <UserOutlined/>,
                        label: 'Користувачі',
                        onClick: () => {
                            navigate('/dashboard/users')
                        },
                    },
                    {
                        key: '8',
                        icon: <SettingOutlined/>,
                        label: 'Налаштування',
                        onClick: () => {
                            navigate('/dashboard/setting')
                        },
                    },
                    {
                        key: '9',
                        icon: <LogoutOutlined/>,
                        label: 'Вийти',
                        onClick: () => {
                            authContext.logout();
                        }
                    }
                ]}
            />
        </Sider>
        <Layout>
            <Header
                style={{
                    padding: 0,
                    background: colorBgContainer,
                }}
            >
                <Button
                    className={'sider_collapsed_button'}
                    size={'small'}
                    icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                    onClick={() => setCollapsed(!collapsed)}
                />
                <div className={'header_two_content'}>
                    <img src={'https://resource.hillary.ua/img/logo/logo_mine_black.png'} alt={'logo'} width={111}/>
                </div>
            </Header>
            <Content
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    minHeight: 280,
                    background: colorBgContainer,
                }}
            >
                <Routes>
                    <Route index element={<UtmStatistic/>}/>
                    <Route path="home" element={<>
                        <Skeleton loading={true} active avatar/>
                        <Skeleton loading={true} active avatar/>
                        <Skeleton loading={true} active avatar/>
                    </>}/>
                    <Route path="sites" element={<>
                        <Row>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                        </Row>
                    </>}/>
                    <Route path="products" element={<>
                        <Row>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                            <Col span={8}>
                                <Skeleton loading={true} active avatar/>
                            </Col>
                        </Row>
                    </>}/>
                    <Route path="system" element={<h1>Системне</h1>}/>
                    <Route path="modules" element={<>
                        <CoreModules/>
                    </>}/>
                    <Route path="users" element={<>
                        <Result
                            status="500"
                            title="500"
                            subTitle="Шось пішло не так."
                            extra={<Button type="primary">Повторити</Button>}
                        />
                    </>}/>
                    <Route path="setting" element={<>
                        <Result
                            status="500"
                            title="500"
                            subTitle="Шось пішло не так."
                            extra={<Button type="primary">Повторити</Button>}
                        />
                    </>}/>
                    <Route path="utm" element={<Navigate to={'/'}/>}/>
                </Routes>
            </Content>
        </Layout>
    </Layout>;
};

export default AdminPanel;
