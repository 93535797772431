import React, {useState} from 'react';
import {Button, Input, Form, message} from 'antd';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const LoginForm = () => {
    const [username, setUsername] = useState('bobmv');
    const [password, setPassword] = useState('test');
    const {login} = useAuth();
    const navigate = useNavigate(); // Отримання об'єкту history з react-router-dom

    const handleSubmit = async (values) => {
        const {username, password} = values;
        if (await login(username, password)) {
            // Використовуйте history.push для навігації до /dashboard
            navigate('/dashboard');
        } else {
            message.error('Невірний логін або пароль');
        }
    };

    return (<div className={'loginpage_root'}>
            <Form onFinish={handleSubmit}>
                <div className={'logoroot'}>
                    <img src={'https://resource.hillary.ua/img/logo/logo_mine_black.png'} alt={'logo'} width={111}/>
                </div>
                <Form.Item
                    //label="Логін"
                    name="username"
                    rules={[{required: true, message: 'Будь ласка, введіть ваш логін!'}]}
                >
                    <Input placeholder={'логін'} onChange={e => setUsername(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    //label="Пароль"
                    name="password"
                    rules={[{required: true, message: 'Будь ласка, введіть ваш пароль!'}]}
                >
                    <Input.Password placeholder={'пароль'} onChange={e => setPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button className={'login_button'} type="primary" htmlType="submit">Вхід</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginForm;
