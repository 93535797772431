import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    DotChartOutlined,
    LoadingOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    MenuUnfoldOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Button, Card, Col, Input, Layout, Menu, Row, Spin, Statistic, Table, Tag, theme, Typography} from 'antd';
import {AuthContext} from '../../contexts/AuthContext';
import axios from "axios";
import Highlighter from 'react-highlight-words';
import {Route, Routes, useNavigate} from "react-router-dom";

const {Title} = Typography;
const utm_source_design_data = [
    {
        "id": null,
        "title": "не визначено",
        "icon": "https://resource.hillary.ua/img/icon/empty.png",
        "color": "#000000",
        "keywords": [null]
    },
    {
        "id": "google",
        "title": "Google",
        "icon": "https://resource.hillary.ua/img/icon/google.svg",
        "color": "#4285f4",
        "keywords": ["google", "gclid"]
    },
    {
        "id": "admitad",
        "title": "Admitad",
        "icon": "https://resource.hillary.ua/img/icon/admitad_small.jpg",
        "color": "#ff6600",
        "keywords": ["admitad"]
    },
    {
        "id": "facebook",
        "title": "Facebook",
        "icon": "https://resource.hillary.ua/img/icon/facebook_square.png",
        "color": "#3b5998",
        "keywords": ["facebook", "fbclid"]
    },
    {
        "id": "instagram",
        "title": "Instagram",
        "icon": "https://resource.hillary.ua/img/icon/instagram_ghost.svg",
        "color": "#e4405f",
        "keywords": ["instagram", "igshid", "igclid", "inst"]
    },
    {
        "id": "salesdoubler",
        "title": "Salesdoubler",
        "icon": "https://resource.hillary.ua/img/icon/salesdoubler.png",
        "color": "#ff6600",
        "keywords": ["salesdoubler"]
    },
    {
        "id": "actionpay",
        "title": "Actionpay",
        "icon": "https://resource.hillary.ua/img/icon/actionpay.jpg",
        "color": "#ff6600",
        "keywords": ["actionpay"]
    },
    {
        "id": "bing",
        "title": "Bing",
        "icon": "https://resource.hillary.ua/img/icon/bing.png",
        "color": "#ffb900",
        "keywords": ["bing"]
    },
    {
        "id": "gms",
        "title": "GMS",
        "icon": "https://resource.hillary.ua/img/icon/GMS.png",
        "color": "#ff6600",
        "keywords": ["gms"]
    },
    {
        "id": "sendpulse",
        "title": "Sendpulse",
        "icon": "https://resource.hillary.ua/img/icon/sendpulse.jpg",
        "color": "#ff6600",
        "keywords": ["sendpulse"]
    },
    {
        "id": "telegram",
        "title": "Telegram",
        "icon": "https://resource.hillary.ua/img/icon/telegram.svg",
        "color": "#0088cc",
        "keywords": ["telegram", "tg"]
    },
    {
        "id": "affise",
        "title": "Affise",
        "icon": "https://resource.hillary.ua/img/icon/affise.ico",
        "color": "#ff6600",
        "keywords": ["affise"]
    },
    {
        "id": "wayforpay",
        "title": "Wayforpay",
        "icon": "https://resource.hillary.ua/img/icon/wayforpay.png",
        "color": "#ff6600",
        "keywords": ["wayforpay"]
    },
    {
        "id": "monobank",
        "title": "Monobank",
        "icon": "https://resource.hillary.ua/img/icon/monobank.png",
        "color": "#ff6600",
        "keywords": ["monobank", "mbnk"]
    },
    {
        "id": "sellaction",
        "title": "Sellaction",
        "icon": "https://resource.hillary.ua/img/icon/sellaction.jpg",
        "color": "#ff6600",
        "keywords": ["sellaction"]
    },
    {
        "id": "ss",
        "title": "SmartSender",
        "icon": "https://resource.hillary.ua/img/icon/ss.svg",
        "color": "#ff6600",
        "keywords": ["ss"]
    },
    {
        "id": "liqpay",
        "title": "Liqpay",
        "icon": "https://resource.hillary.ua/img/icon/liqpay.webp",
        "color": "#ff6600",
        "keywords": ["liqpay"]
    },
    {
        "id": "none",
        "title": "None",
        "icon": "https://resource.hillary.ua/img/icon/info.png",
        "color": "#000000",
        "keywords": ["none", "null", "undefined", "direct"]
    },
    {
        "id": "Youtube",
        "title": "Youtube",
        "icon": "https://resource.hillary.ua/img/icon/youtube.png",
        "color": "#ff0000",
        "keywords": ["youtube"]
    },
    {
        "id": "tiktok",
        "title": "Tiktok",
        "icon": "https://resource.hillary.ua/img/icon/tiktok.png",
        "color": "#000000",
        "keywords": ["tiktok"]
    },
    {
        "id": "esputnik",
        "title": "Esputnik",
        "icon": "https://resource.hillary.ua/img/icon/esputnik.png",
        "color": "#ff6600",
        "keywords": ["esputnik"]
    },
    {
        "id": "prom",
        "title": "Prom",
        "icon": "https://resource.hillary.ua/img/icon/prom.png",
        "color": "#ff6600",
        "keywords": ["prom"]
    }
]

const {Header, Sider, Content} = Layout;
const UtmStatistic = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState(''); // Стан для зберігання рядка пошуку
    const [filteredData, setFilteredData] = useState(data); // Стан для фільтрованих даних таблиці
    const authContext = useContext(AuthContext)
    const navigate = useNavigate(); // Отримання об'єкту history з react-router-dom

    const {
        token: {colorBgContainer},
    } = theme.useToken();
    const onSearchText = (e) => {
        setSearchText(e.target.value);
    }
    const utm_filters = useMemo(() => {
            const utm_source = new Set();
            const utm_medium = new Set();
            const utm_campaign = new Set();
            const utm_content = new Set();
            const utm_term = new Set();
            data.forEach((item) => {
                utm_source.add(item.utm_source);
                utm_medium.add(item.utm_medium);
                utm_term.add(item.utm_term);
            });
            return {
                utm_source: [...utm_source.values()].map((value) => ({text: value, value})),
                utm_medium: [...utm_medium.values()].map((value) => ({text: value, value})),
                utm_campaign: [...utm_campaign.values()].map((value) => ({text: value, value})),
                utm_content: [...utm_content.values()].map((value) => ({text: value, value})),
                utm_term: [...utm_term.values()].map((value) => ({text: value, value})),
            };
        }
        , [data]);
    const money_formatter = new Intl.NumberFormat('uk-UA', {
        style: 'currency',
        currency: 'UAH',
    });
    const find_utm_source_design_by_keywords = (utm_source) => {
        return utm_source_design_data.find((item) => {
            if (item.keywords) {
                for (let i = 0; i < item.keywords.length; i++) {
                    if (utm_source === null || utm_source === undefined) {
                        return true;
                    }
                    if (utm_source && utm_source.toLowerCase().indexOf(item.keywords[i]) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        });
    }
    const columns = [
        {
            title: '',
            width: 33,
            render: (text, record) => {
                const utm_source_design = find_utm_source_design_by_keywords(record.utm_source)
                return <div className={'utm_traffic_logo'}>
                    <img src={utm_source_design ? utm_source_design.icon : ''} alt={'i'} width={22}/>
                </div>;
            }
        },
        {
            title: 'Source',
            width: 160,
            dataIndex: 'utm_source',
            key: 'utm_source',
            filters: utm_filters.utm_source,
            filterSearch: true,
            onFilter: (value, record) => {
                if (record.utm_source && typeof record.utm_source === 'string') {
                    return record.utm_source.startsWith(value);
                }
                return false; // якщо record.utm_source є null або undefined
            },
            render: (text, record) => {
                return <>
                    <Tag className={'utm_source_value utm_value'}>
                        {record.utm_source}
                    </Tag>
                </>;
            }
        },
        {
            title: 'Medium',
            dataIndex: 'utm_medium',
            key: 'utm_medium',
            filters: utm_filters.utm_medium,
            filterSearch: true,
            width: 100,
            textWrap: 'word-break',
            ellipsis: true,
            onFilter: (value, record) => {
                if (record.utm_medium && typeof record.utm_medium === 'string') {
                    return record.utm_medium.startsWith(value);
                }
                return false; // якщо record.utm_medium є null або undefined
            },
            render: (text, record) => {
                return <>
                    <Highlighter
                        className={'utm_value'}
                        key={searchText}
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={record?.utm_medium}
                    />
                </>;
            }
        },
        {
            title: 'Campaign',
            dataIndex: 'utm_campaign',
            key: 'utm_campaign',
            filterSearch: true,
            width: 100,
            render: (text, record) => {
                return <>
                    <Highlighter
                        className={'utm_value'}
                        key={searchText}
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={record?.utm_campaign}
                    />
                </>;
            }
        },
        {
            title: 'Content',
            dataIndex: 'utm_content',
            key: 'utm_content',
            filterSearch: true,
            width: 100,
            render: (text, record) => {
                return <>
                    <Highlighter
                        className={'utm_value'}
                        key={searchText}
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={record?.utm_content}
                    />
                </>;
            }
        },
        {
            title: 'Term',
            dataIndex: 'utm_term',
            key: 'utm_term',
            filters: utm_filters.utm_term,
            filterSearch: true,
            width: 100,
            onFilter: (value, record) => {
                if (record.utm_term && typeof record.utm_term === 'string') {
                    return record.utm_term.startsWith(value);
                }
                return false; // якщо record.utm_term є null або undefined
            },
            render: (text, record) => {
                return <>
                    <Highlighter
                        className={'utm_value'}
                        key={searchText}
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={record.utm_term}
                    />
                </>;
            }
        },
        {
            title: 'Загалом',
            width: 156,
            dataIndex: 'total_sum',
            key: 'total_sum',
            sorter: (a, b) => a.total_sum - b.total_sum,
            render: (text, record) => {
                return <nobr><b className={'total_sum_value'}>{money_formatter.format(record.total_sum)}</b></nobr>;
            }
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://core.hillary.ua/api/test/test'); // Replace with your API endpoint
                setData(response.data);  // Assume the response data is in the format you expect
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        // Функція фільтрації даних на основі рядка пошуку
        const filterData = () => {
            const filtered = data.filter((item) => {
                const searchTextEmpty = searchText.trim().length === 0; // Проверяем, что строка поиска пуста или содержит только пробелы

                const sourceMatches = item.utm_source
                    ? item.utm_source.toLowerCase().includes(searchText.toLowerCase())
                    : true; // Показываем элементы с null в utm_source, только если строка поиска пуста

                const mediumMatches = item.utm_medium
                    ? item.utm_medium.toLowerCase().includes(searchText.toLowerCase())
                    : true; // Показываем элементы с null в utm_medium, только если строка поиска пуста

                const campaignMatches = item.utm_campaign
                    ? item.utm_campaign.toLowerCase().includes(searchText.toLowerCase())
                    : true; // Показываем элементы с null в utm_campaign, только если строка поиска пуста

                const contentMatches = item.utm_content
                    ? item.utm_content.toLowerCase().includes(searchText.toLowerCase())
                    : true; // Показываем элементы с null в utm_content, только если строка поиска пуста

                const termMatches = item.utm_term
                    ? item.utm_term.toLowerCase().includes(searchText.toLowerCase())
                    : true; // Показываем элементы с null в utm_term, только если строка поиска пуста

                if (searchTextEmpty) {
                    return true;
                }
                // Включаем элемент, если строка поиска пуста или хотя бы одно поле соответствует строке поиска
                let utmNullMatchesOr = sourceMatches || mediumMatches || campaignMatches || contentMatches || termMatches;
                let utmNullMatchesAnd = sourceMatches && mediumMatches && campaignMatches && contentMatches && termMatches;
                if (!searchTextEmpty && utmNullMatchesAnd) {
                    return false;
                }
                return utmNullMatchesOr;
            });
            setFilteredData(filtered); // Обновляем отфильтрованные данные таблицы
        };


        filterData(); // Викликайте функцію фільтрації при зміні рядка пошуку
    }, [searchText, data]);

    if (isLoading)
        return <Spin className={'loaderroot'} indicator={<LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />}/>
    return <>
        <Row gutter={16} className={'statistic_cards'}>
            <Col>
                <Card bordered={false} size={'small'}>
                    <Statistic
                        title="Active"
                        value={11.28}
                        precision={2}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col>
                <Card bordered={false}  size={'small'}>
                    <Statistic
                        title="Idle"
                        value={9.3}
                        precision={2}
                        valueStyle={{
                            color: '#cf1322',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col>
                <Card bordered={false}  size={'small'}>
                    <Statistic
                        title="Idle"
                        value={9.3}
                        precision={2}
                        valueStyle={{
                            color: '#cf1322',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col>
                <Card bordered={false}  size={'small'}>
                    <Statistic
                        title="Idle"
                        value={9.3}
                        precision={2}
                        valueStyle={{
                            color: '#cf1322',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col>
                <Card bordered={false}  size={'small'}>
                    <Statistic
                        title="Idle"
                        value={9.3}
                        precision={2}
                        valueStyle={{
                            color: '#cf1322',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col>
                <Card bordered={false}  size={'small'}>
                    <Statistic
                        title="Idle"
                        value={9.3}
                        precision={2}
                        valueStyle={{
                            color: '#cf1322',
                        }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
        </Row>
        <Title level={3} style={{textAlign: "center"}}>Статистика UTM</Title>
        <Input
            className={'search_input'}
            size={'large'}
            placeholder="Пошук"
            value={searchText}
            onChange={onSearchText}
            addonBefore={<SearchOutlined/>}
        />
        <Table
            dataSource={filteredData}
            columns={columns}
            size={'small'}
            pagination={{
                position: ['topCenter'],
                pageSizeOptions: [10, 50, 100, 200],
                defaultPageSize: 10,
                showTotal: (total, range) => `${range[0]}-${range[1]} з ${total} елементів`,
            }}
        />
    </>;
};

export default UtmStatistic;
