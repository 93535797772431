import React, {useContext, useState} from 'react';
import {HappyProvider} from '@ant-design/happy-work-theme';
import {BrowserRouter as Router, Route, Navigate, Routes, useNavigate} from 'react-router-dom';
import LoginForm from './views/Login';
import AdminPanel from './views/AdminPanel';
import {AuthProvider, AuthContext} from './contexts/AuthContext';
import useAuth from "./hooks/useAuth";
import uk_UA from 'antd/lib/locale/uk_UA'; // Імпортуйте локалізацію для української мови

import './App.css';
import {theme} from 'antd';
import {
    Button,
    ColorPicker,
    ConfigProvider,
    Divider,
    Form,
    Input,
    InputNumber, Result,
    Space,
    Switch,
} from 'antd';
const { darkAlgorithm, compactAlgorithm } = theme;
const defaultData = {
    motion: true,
    borderRadius: 11,
    colorPrimary: '#6bd466',
    Button: {
        colorPrimary: '#2f6921',
    },
};
const createHolder = (node) => {
    const {borderWidth} = getComputedStyle(node);
    const borderWidthNum = parseInt(borderWidth, 10);
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.inset = `-${borderWidthNum}px`;
    div.style.borderRadius = 'inherit';
    div.style.background = 'transparent';
    div.style.zIndex = '999';
    div.style.pointerEvents = 'none';
    div.style.overflow = 'hidden';
    node.appendChild(div);
    return div;
};
const createDot = (holder, color, left, top, size = 0) => {
    const dot = document.createElement('div');
    dot.style.position = 'absolute';
    dot.style.left = `${left}px`;
    dot.style.top = `${top}px`;
    dot.style.width = `${size}px`;
    dot.style.height = `${size}px`;
    dot.style.borderRadius = '50%';
    dot.style.background = color;
    dot.style.transform = 'translate(-50%, -50%)';
    dot.style.transition = `all 1s ease-out`;
    holder.appendChild(dot);
    return dot;
};

// Inset Effect
const showInsetEffect = (node, {event, component}) => {
    if (component !== 'Button') {
        return;
    }
    const holder = createHolder(node);
    const rect = holder.getBoundingClientRect();
    const left = event.clientX - rect.left;
    const top = event.clientY - rect.top;
    const dot = createDot(holder, 'rgba(255, 255, 255, 0.65)', left, top);

    // Motion
    requestAnimationFrame(() => {
        dot.ontransitionend = () => {
            holder.remove();
        };
        dot.style.width = '200px';
        dot.style.height = '200px';
        dot.style.opacity = '0';
    });
};

// Shake Effect
const showShakeEffect = (node, {component}) => {
    if (component !== 'Button') {
        return;
    }
    const seq = [0, -15, 15, -5, 5, 0];
    const itv = 10;
    let steps = 0;

    function loop() {
        cancelAnimationFrame(node.effectTimeout);
        node.effectTimeout = requestAnimationFrame(() => {
            const currentStep = Math.floor(steps / itv);
            const current = seq[currentStep];
            const next = seq[currentStep + 1];
            if (!next) {
                node.style.transform = '';
                node.style.transition = '';
                return;
            }

            // Trans from current to next by itv
            const angle = current + ((next - current) / itv) * (steps % itv);
            node.style.transform = `rotate(${angle}deg)`;
            node.style.transition = 'none';
            steps += 1;
            loop();
        });
    }

    loop();
};

const ProtectedRoute = ({user, children}) => {
    if (!user)
        return <Navigate to="/" replace/>
    return children
};
const PublicRoute = ({user, children}) => {
    if (user)
        return <Navigate to={'/dashboard'}/>
    return children
};

const App = () => {
    const [data, setData] = useState(defaultData);
    const [showNotFound, setShowNotFound] = useState(false); // Додайте стан для відображення 404
    const navigate = useNavigate(); // Отримання об'єкту history з react-router-dom

    const {user} = useContext(AuthContext);
    const handleBackHome = () => {
        setShowNotFound(false); // Сховати елемент Result 404
        navigate('/'); // Перенаправити на головну сторінку
    };

    return (
        <ConfigProvider
            locale={uk_UA}
            theme={{
                //algorithm: theme.defaultAlgorithm,
                algorithm: theme.darkAlgorithm,
                token: {
                    colorPrimary: data.colorPrimary,
                    borderRadius: data.borderRadius,
                },
                components: {
                    Button: {
                        colorPrimary: data.Button?.colorPrimary,
                        algorithm: data.Button?.algorithm,
                    },
                },
            }}
        >
            <Routes>
                <Route index element={<Navigate to="/login"/>}/>
                <Route
                    path='/login'
                    element={
                        <PublicRoute user={user}>
                            <LoginForm/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/dashboard/*"
                    element={
                        <ProtectedRoute user={user}>
                            <AdminPanel/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="*"
                    element={
                        showNotFound ? (
                            <Result
                                status="404"
                                title="404"
                                subTitle="Sorry, the page you visited does not exist."
                                extra={<Button type="primary" onClick={handleBackHome}>Back Home</Button>}
                            />
                        ) : <Navigate to={'/'}/>
                    }
                />
            </Routes>
        </ConfigProvider>
    );
};

export default App;
