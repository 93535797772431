import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./contexts/AuthContext";
import {BrowserRouter as Router} from 'react-router-dom';
import {HappyProvider} from "@ant-design/happy-work-theme"; // Імпортуйте Router з react-router-dom

const element = document.querySelector('.adpr_root');
const root = ReactDOM.createRoot(element)
root.render(
    <React.StrictMode>
        <Router>
            <AuthProvider>
                <HappyProvider>
                    <App/>
                </HappyProvider>
            </AuthProvider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
