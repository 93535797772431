import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    DotChartOutlined,
    LoadingOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    ArrowDownOutlined,
    SettingOutlined,
    ArrowUpOutlined,
    EllipsisOutlined,
    EditOutlined,
    MenuUnfoldOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    Avatar,
    Button,
    Card,
    Col,
    Input,
    Layout,
    Menu,
    Row,
    Spin,
    Statistic,
    Table,
    Tag,
    theme,
    Typography
} from 'antd';
import {AuthContext} from '../../contexts/AuthContext';
import axios from "axios";

const {Meta} = Card;
const CoreModules = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState(''); // Стан для зберігання рядка пошуку
    const [filteredData, setFilteredData] = useState(data); // Стан для фільтрованих даних таблиці
    const authContext = useContext(AuthContext)

    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const modules = [
        {
            title: 'Користувачі',
            icon: <UserOutlined/>,
            description: 'Користувачі системи',
            link: '/dashboard/users',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/users-header.jpg',
        },
        {
            title: 'APN',
            icon: <UserOutlined/>,
            description: 'APN',
            link: '/dashboard/apn',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/notifyapn-header.webp',
        },
        {
            title: 'LCW',
            icon: <UserOutlined/>,
            description: 'LCW',
            link: '/dashboard/lcw',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/traffic-header.png',
        },
        {
            title: 'AdPulse',
            icon: <UserOutlined/>,
            description: 'AdPulse',
            link: '/dashboard/adpulse',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/widget-header.jpg',
        },
        {
            title: 'Ресурси',
            icon: <UserOutlined/>,
            description: 'Ресурси',
            link: '/dashboard/resources',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/resources-header.jpeg',
        },
        {
            title: 'Діловод',
            icon: <UserOutlined/>,
            description: 'Діловод',
            link: '/dashboard/dilovod',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/dilovod-header.png',
        },
        {
            title: 'Розсилка',
            icon: <UserOutlined/>,
            description: 'Розсилка',
            link: '/dashboard/mailing',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/newsletters-header.png',
        },
        {
            title: 'Генератор',
            icon: <UserOutlined/>,
            description: 'Генератор',
            link: '/dashboard/generator',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/generator-header.jpeg',
        },
        {
            title: 'CoreLogger',
            icon: <UserOutlined/>,
            description: 'CoreLogger',
            link: '/dashboard/corelogger',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/logs-header.jpg',
        },
        {
            title: 'AsterilCRM',
            icon: <UserOutlined/>,
            description: 'AsterilCRM',
            link: '/dashboard/asterilcrm',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/crm-header.jpg',
        },
        {
            title: 'Хуки',
            icon: <UserOutlined/>,
            description: 'Хуки',
            link: '/dashboard/hooks',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/webhook-header.png',
        },
        {
            title: 'Schedule',
            icon: <UserOutlined/>,
            description: 'Schedule',
            link: '/dashboard/schedule',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/cron-header.png',
        },
        {
            title: 'Валідатор',
            icon: <UserOutlined/>,
            description: 'Валідатор',
            link: '/dashboard/validator',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/validator-header.jpg',
        },
        {
            title: 'DynoConfig',
            icon: <UserOutlined/>,
            description: 'DynoConfig',
            link: '/dashboard/dynoconfig',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/config-header.jpg',
        },
        {
            title: 'CacheVar',
            icon: <UserOutlined/>,
            description: 'CacheVar',
            link: '/dashboard/cachevar',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/cachevar-header.webp',
        },
        {
            title: 'Affise',
            icon: <UserOutlined/>,
            description: 'Affise',
            link: '/dashboard/affise',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/affise-header.jpg',
        },
        {
            title: 'Esputnik',
            icon: <UserOutlined/>,
            description: 'Esputnik',
            link: '/dashboard/esputnik',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/esputnik-header.jpg',
        },
        {
            title: 'Партнерська програма',
            icon: <UserOutlined/>,
            description: 'Партнерська програма',
            link: '/dashboard/partnership_program',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/partner-program-header.jpg',
        },
        {
            title: 'Товарні фіди',
            icon: <UserOutlined/>,
            description: 'Товарні фіди',
            link: '/dashboard/product_feeds',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/products-header.png',
        },
        {
            title: 'Сервери',
            icon: <UserOutlined/>,
            description: 'Сервери',
            link: '/dashboard/servers',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/server-header.png',
        },
        {
            title: 'Локальна історія',
            icon: <UserOutlined/>,
            description: 'Локальна історія',
            link: '/dashboard/local_history',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/history-header.jpg',
        },
        {
            title: 'Facebook Multipixel',
            icon: <UserOutlined/>,
            description: 'Facebook Multipixel',
            link: '/dashboard/facebook_multipixel',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/facebook-header.jpg',
        },
        {
            title: 'TelegramBot',
            icon: <UserOutlined/>,
            description: 'TelegramBot',
            link: '/dashboard/telegrambot',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/botfather-header.png',
        },
        {
            title: 'Скорочувач посилань',
            icon: <UserOutlined/>,
            description: 'Скорочувач посилань',
            link: '/dashboard/link_shortener',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/urlshort-header.png',
        },
        {
            title: 'Docker',
            icon: <UserOutlined/>,
            description: 'Docker',
            link: '/dashboard/docker',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/docker.webp',
        },
        {
            title: 'Portainer',
            icon: <UserOutlined/>,
            description: 'Portainer',
            link: '/dashboard/portainer',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/portainer-header.png',
        },
        {
            title: 'git',
            icon: <UserOutlined/>,
            description: 'git',
            link: '/dashboard/git',
            type: 'info',
            count: 0,
            image: 'https://resource.hillary.ua/img/headers/git-blog-header.png',
        }
    ];

    return <>
        <Row gutter={16} className={'module_cards'}>
            {modules.map((module, index) => (
                <Col span={12} key={index}>
                    <Card
                        className={'module_card'}
                        hoverable
                        cover={<div className={'module_card_cover'} style={{backgroundImage:`url(${module.image})`}}></div>}
                        actions={[
                            <SettingOutlined key="setting" />,
                            <EllipsisOutlined key="ellipsis" />,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                            title={module.title}
                            description={module.description}
                        />
                    </Card>
                </Col>
            ))}
        </Row>
    </>;
};

export default CoreModules;
