import axios from 'axios';

const authService = {
    login: async (username, password) => {
        try {
            const response = await axios.post('https://core.hillary.ua/api/secret/gh6lk12/login', {
                username,
                password
            });
            if (response.data && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                return true;
            }
        } catch (error) {
            console.error('Помилка авторизації:', error);
        }
        return false;
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    },
    getCurrentUser: () => {
        return JSON.parse(localStorage.getItem('user'));
    },
    isAuthenticated: () => {
        return localStorage.getItem('token') !== null;
    }
};
export default authService;